@import "~bootstrap/scss/bootstrap";
@import "./styles/autocomplete.scss";

main {
  padding-top: 2rem;
}

.logo {
  height: auto;
  width: 250px;
}

pre {
  white-space: pre-wrap;
  background-color: #d3d3d3;
  padding: 1rem;
  border-radius: 0.2rem;
}

.icon-text {
  display: inline;
  padding-left: 0.5rem;
}

.bid-items-list {
  padding: 0;

  table {
    margin-bottom: 0;
  }
}

.card-bottom {
  margin-bottom: 10px;
}