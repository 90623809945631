.autocomplete-items {
  position: absolute;
  box-sizing: border-box;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 500px;
  overflow-y: auto;
  padding-left: 0;
  z-index: 100;
}

.autocomplete-item:hover {
  color: $white;
  background-color: $primary;
  cursor: pointer;
}

.autocomplete-item > img {
  padding-right: 10px;
}
